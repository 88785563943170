import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query ReviewQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___order }
        filter: { frontmatter: { category: { eq: "review" } } }
      ) {
        edges {
          node {
            frontmatter {
              review
              author
              image {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `)
  return (
    <div className="section reviews">
      <div className="container">
        <div className="content">
          <h2>Ce spun clienții noștri</h2>
          <div className="columns is-variable is-8-widescreen" data-sal="fade">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div className="column" data-sal="slide-up" key={node.id}>
                <p className="review-body">{node.frontmatter.review}</p>
                <div className="review-author">
                  <figure className="image is-48x48 is-rounded">
                    <Img
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                      alt={node.frontmatter.author}
                    />
                  </figure>
                  <div className="review-name">{node.frontmatter.author}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Reviews
